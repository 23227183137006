<template>
  <div class="flex flex-col">
    <portal-target name="modals" multiple></portal-target>
    <w-confirm />
    <notifications position="top center">
      <template slot="body" slot-scope="props">
        <div class="rounded relative mt-2" :class="[
            {'bg-red-700': props.item.type == 'error'},
            {'bg-gray-800': props.item.type != 'error'}
          ]">
          <div class="flex p-4">
            <div class="text-white pl-6 pr-1 py-3 flex-1">
              <div class="font-medium">
                {{props.item.title}}
              </div>
              <div v-html="props.item.text"></div>
            </div>
            <div>
              <div class="cursor-pointer pt-3 pr-3 text-white opacity-50 hover:opacity-100" @click="props.close">
                <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </template>
    </notifications>

    <div v-if="appLoading" class="flex items-center justify-center h-64 p-6">
      <w-loading />
    </div>

    <div v-else class="flex w-full max-w-screen-xl mx-auto flex-1 text-gray-800">
      <div
        v-if="!_inIframe"
        class="border-r xl:border-l fixed flex flex-col justify-between h-full w-72 bg-gray-50">
        <div class="px-6 py-3">
          <div class="flex items-center mb-6 py-3"><span class="text-2xl font-bold">Shopify &middot; {{ consumerName }}</span></div>

          <template v-for="(menu, index) in menus">
            <div :key="index" class="-mx-3">
              <router-link 
                class="flex justify-between border border-gray-50 text-gray-500 font-medium hover:bg-gray-100 hover:no-underline px-3 py-2 rounded-md"
                :exact="menu.exact"
                active-class="text-gray-800 bg-gray-100"
                exact-active-class="text-gray-800 bg-gray-100"
                :to="{ name: menu.name, query: menu.query}">
                {{ menu.label }}
                <w-badge v-if="menu.name == 'products' && productsToMatch > 0">
                  {{ productsToMatch }}
                </w-badge>
              </router-link>
            </div>
          </template>

          <div class="flex items-center py-3">
            <div class="p-1">
              <svg class="w-4 mr-1 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" fill="currentColor">
                <path d="M388.32,104.1a4.66,4.66,0,0,0-4.4-4c-2,0-37.23-.8-37.23-.8s-21.61-20.82-29.62-28.83V503.2L442.76,472S388.72,106.5,388.32,104.1ZM288.65,70.47a116.67,116.67,0,0,0-7.21-17.61C271,32.85,255.42,22,237,22a15,15,0,0,0-4,.4c-.4-.8-1.2-1.2-1.6-2C223.4,11.63,213,7.63,200.58,8c-24,.8-48,18-67.25,48.83-13.61,21.62-24,48.84-26.82,70.06-27.62,8.4-46.83,14.41-47.23,14.81-14,4.4-14.41,4.8-16,18-1.2,10-38,291.82-38,291.82L307.86,504V65.67a41.66,41.66,0,0,0-4.4.4S297.86,67.67,288.65,70.47ZM233.41,87.69c-16,4.8-33.63,10.4-50.84,15.61,4.8-18.82,14.41-37.63,25.62-50,4.4-4.4,10.41-9.61,17.21-12.81C232.21,54.86,233.81,74.48,233.41,87.69ZM200.58,24.44A27.49,27.49,0,0,1,215,28c-6.4,3.2-12.81,8.41-18.81,14.41-15.21,16.42-26.82,42-31.62,66.45-14.42,4.41-28.83,8.81-42,12.81C131.33,83.28,163.75,25.24,200.58,24.44ZM154.15,244.61c1.6,25.61,69.25,31.22,73.25,91.66,2.8,47.64-25.22,80.06-65.65,82.47-48.83,3.2-75.65-25.62-75.65-25.62l10.4-44s26.82,20.42,48.44,18.82c14-.8,19.22-12.41,18.81-20.42-2-33.62-57.24-31.62-60.84-86.86-3.2-46.44,27.22-93.27,94.47-97.68,26-1.6,39.23,4.81,39.23,4.81L221.4,225.39s-17.21-8-37.63-6.4C154.15,221,153.75,239.8,154.15,244.61ZM249.42,82.88c0-12-1.6-29.22-7.21-43.63,18.42,3.6,27.22,24,31.23,36.43Q262.63,78.68,249.42,82.88Z"/>
              </svg>
            </div>
            <div class="leading-tight text-gray-500">
              <div class="flex items-center font-medium" v-if="account && account.provider && account.provider.details && account.provider.details.shop">{{ account.provider.details.shop.domain.replace('.myshopify.com','') }}</div>
              <div class="" v-else>
                <div>No connection with Shopify</div>
              </div>
            </div>
          </div>
          <w-button v-if="!account || !account.consumer" class="w-full" :to="{name: 'shopify-install'}">Install the App</w-button>
        </div>
        <div class="px-6 border-t py-6">
          <w-button v-if="!isAuthentified" primary class="w-full" :to="{ name: 'users-auth' }">Log In or Register</w-button>

          <div v-else>
            <!-- text-overflow: ellipsis;
    overflow: hidden; -->
            <div class="font-semibold overflow-auto overflow-ellipsis">{{ profile.email }}</div>
            <router-link class="flex py-2 cursor-pointer" :to="{name: 'users-logout' }">
              <svg class="w-5 mr-2 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
              </svg>
              <span class="text-gray-700 font-medium">Logout</span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col flex-1 min-h-screen justify-between" :class="{'ml-72 bg-gray-50': !_inIframe}">
        <router-view/>
        <div class="border-t flex flex-col items-center justify-center h-20">
          <div>WalktheChat - All Rights Reserved</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VClamp from 'vue-clamp'
import Utils from '@/plugins/utils'

export default {
  components: { VClamp },
  data () {
    return { 
    }
  },
  computed: {
    _search() {
      if(window && window.location) {
        return window.location.search.split('&')
      }
    },
    ...mapState({
      appLoading: state => state.context.appLoading,
      signature: state => state.shopify.signature
    }),
    ...mapGetters({
      isAuthentified: 'users/isAuthentified',
      profile: 'users/getProfile',
      account: 'shopify/getAccount',
      productsToMatch: 'redProducts/getCount',
      consumerName: 'context/getConsumerName'
    }),
    menus() {
      return [
        { label: 'Account', name: 'index', exact: true},
        { label: 'Products', name: 'products', exact: false },
        { label: 'Preferences', name: 'red-preferences', exact: true},
        { label: 'Notifications', name: 'red-notifications', exact: true}
      ].filter(m => m)
    },
    _inIframe() {
      return Utils.inIframe()
    }
  }
}
</script>